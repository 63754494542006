<template>
    <div class="container flex px-5 mx-auto md:max-w-6xl" v-if="products">
        <div class="flex flex-col w-full py-5">
            <div class="flex p-4 bg-white shadow">
                <span class="flex items-center justify-end w-auto p-2 text-gray-500">
                    <SearchIcon class="self-center w-6 h-6 text-gray-200 md:w-6 md:h-6" aria-hidden="true" />
                </span>
                <input class="w-full p-2 rounded" v-model="search" type="text" placeholder="Hierba luisa">
                <div @click="getSearchResult" class="p-2 pl-4 pr-4 text-white bg-green-400 rounded cursor-pointer hover:bg-green-300">
                        <p class="text-xs font-semibold">Buscar</p>
                </div>
            </div>
            <div class="flex flex-row py-5 mx-auto space-x-5 md:py-8 md:space-x-24">
                <div class="">
                    <a @click="getAllProducts" class="text-center cursor-pointer hover:underline hover:text-gray-500">Productos</a>
                </div>
                <div class="">
                    <a @click="getAllVendors" class="text-center cursor-pointer hover:underline hover:text-gray-500">Proveedores</a>
                </div>
            </div>

            
            
            
            <div v-if="showVendors">
                <div class="grid gap-y-10 sm:grid-cols-2 gap-x-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 xl:gap-x-8" >
                    <a :href="`vendor/${vendor.id}/`+sanitizeTitle(`${vendor.name}`)"  v-for="vendor in vendors" :key="vendor.id" class="rounded shadow">
                        <img  v-if="vendor" v-bind:src="vendor.feature_image" class="transition duration-500 ease-in-out transform shadow-sm md:h-56 w-72 md:w-full rounded-t-md hover:-translate-y-1 hover:scale-100">
                        <div class="p-3 md:w-full w-72 rounded-b-md bg-gray-50">
                            <p class="mt-2 mb-1 font-semibold truncate">{{ vendor.name }}</p>
                            <div class="flex flex-row ">
                            <p class="flex py-1">
                                <span class="font-light " v-for="star in vendor.rating" :key="star.id">
                                    <starIcon {{ star }} class="self-center w-3 h-3 text-yellow-400 md:mt-0 md:w-6 md:h-6" aria-hidden="true" />
                                </span>
                            </p>
                            </div>
                            <p class="text-xs font-light text-gray-700 truncate">{{ vendor.address}}</p>
                        </div>
                    </a>   
                </div>
            </div>
            <div v-else>
                <div class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
                    <router-link :to="{ name: 'Product', params: { id: product.id, slug: sanitizeTitle(product.name) }}"  v-for="product in products" :key="product.id" class="p-2 rounded shadow group">
                        <div class="w-full overflow-hidden rounded-lg aspect-w-1 aspect-h-1 xl:aspect-w-7 xl:aspect-h-8">
                        <img v-if="product.photo" v-bind:src="product.photo" class="object-cover object-center w-full md:h-52 group-hover:opacity-75" />
                        </div>
                        <h3 class="mt-4 text-sm text-gray-700">
                        {{ product.name }}
                        </h3>
                        <p class="mt-1 text-lg font-medium text-gray-900 ">
                            <span v-if="product.discount_price > 0">
                                <p class="text-lg font-semibold text-gray-900 ">{{ currency }} <span class="line-through">{{ product.price.toFixed(1) }}</span> <span class="text-base">{{ product.discount_price.toFixed(1) }}</span></p>
                            </span>
                            <span v-else>
                                <p class="text-lg font-semibold text-gray-900 ">{{ currency }}{{ product.price.toFixed(2) }}</p>
                            </span>
                        </p>
                        <p class="w-24 p-1 my-5 text-xs text-gray-700 truncate bg-gray-100 rounded-md">{{ product.vendor.name }}</p>
                    </router-link>
                </div>
            </div>
        </div>
       
    </div>
    <Download/> 
</template>
<script>
import axios from 'axios'
import { SearchIcon, ChevronRightIcon } from '@heroicons/vue/outline'
import { StarIcon } from '@heroicons/vue/solid'
import Download from '@/components/Downloads.vue'
export default {
  name: 'Vendors',
  components: {
    SearchIcon,
    StarIcon,
    ChevronRightIcon,
    Download,
  },
  
  data () {
    return {
      vendors: null,
      settings: null,
      base_url: this.$store.state.baseUrl,
      products: null,
      currency: null,
      selectedProducts: null,
      search: null,
      types: null,
      showVendors: false
    }
  },
  
  mounted () {
    this.getCurrency();

    this.$store.commit('loading', true)
    axios.get(this.base_url+'api/products?vendor_type_id='+2)
    .then((response) => {
      this.$store.commit('loading', false)
      this.products = response.data.data
      }
    )
    .catch(error => console.log(error))

  },

  methods: {
    getCurrency() {
      let setting;
      axios.get(this.base_url+'api/app/settings')
      .then( (response) => {
          setting = response.data;
          this.currency = setting.strings.currency
      })
      return this.currency;
    }, 

    getAllProducts()
    {
        this.showVendors = false
        this.$store.commit('loading', true)
        axios.get(this.base_url+'api/products?vendor_type_id='+2)
        .then((response) => {
            this.$store.commit('loading', false)
            this.products = response.data.data
            // console.log(this.products)
        })
        .catch(error => console.log(error))
    },

    getAllVendors()
    {
        this.showVendors = true
        this.$store.commit('loading', true)
        axios.get(this.base_url+'api/vendors?vendor_type_id='+2)
        .then((response) => {
            this.$store.commit('loading', false)
            this.vendors = response.data.data
            // console.log(this.vendors)
        }
        )
        .catch(error => console.log(error))
    },

    getSearchResult()
    {
      this.$store.commit('loading', true)
      axios.get(this.base_url+'api/search?keyword='+this.search)
        .then((response) => {
        //   console.log(response)
          this.$store.commit('loading', false)
          let productSearch
          let vendorSearch
          productSearch = response.data.products.data
          vendorSearch = response.data.vendors.data
          this.products = productSearch
          this.vendors = vendorSearch
        })
        .catch(error => console.log(error))
    },

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd');
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');
      
      return slug;
    },

    
  },

}
</script>